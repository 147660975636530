const slides = [
  [
    { src: '../../images/blueprint1.png' },
    { src: '../../images/blueprint2.png' },
    { src: '../../images/blueprint3.png' },
  ],
  [
    { src: '../../images/ecommerce1.png' },
    { src: '../../images/ecommerce3.png' },
    { src: '../../images/ecommerce6.png' },
  ],
  [
    { src: '../../images/picky2.png' },
    { src: '../../images/picky3.png' },
    { src: '../../images/picky4.png' },
    { src: '../../images/picky5.png' },
    { src: '../../images/picky6.png' },
    { src: '../../images/picky7.png' },
  ],
  [{ src: '../../images/profile-site-min.png' }],
];

export default slides;
